<template>
  <q-chip dense :style="{ background: stc(taskParentState), color: '#fff' }">
    {{ taskParentState }}
  </q-chip>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { TaskModel } from '../../task-model'
import { Prop } from 'vue-property-decorator'
import { ProjectModel } from '@/components/project/project-model'
import { EWorkflowType, IWorkflowState } from '@/components/workflow/workflow-model'
import stc from 'string-to-color'

@Options({
  components: {},
  directives: { maska },
})
export default class TaskParentStatus extends Vue {
  @Prop({ default: {} })
  task!: TaskModel

  stc = stc

  get project(): ProjectModel {
    return this.$store.getters.project
  }

  get workflowStates(): IWorkflowState[] {
    return this.project?.workflow?.states || []
  }

  get stateParentMapping() {
    const mapping: Record<string, string> = {}
    for (const state of this.workflowStates) {
      mapping[state.value] = state.type
    }

    return mapping
  }

  get taskParentState() {
    return this.stateParentMapping[this.task?.state || ''] || EWorkflowType.new
  }
}
</script>
