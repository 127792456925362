<template>
  <q-btn round outline padding="2px" size="xl" align="left">
    <q-icon name="more_horiz" />
    <q-menu style="min-width: 200px" class="q-py-sm" auto-close anchor="bottom right" self="top right">
      <q-list separator dense>
        <q-item clickable v-ripple @click="onTurnOnNotification()">
          <q-item-section avatar>
            <q-icon name="notifications_active" />
          </q-item-section>
          <q-item-section> Turn on notification </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>
<script lang="ts">
import { localStore } from '@/utils/localstore'
import { Options, mixins } from 'vue-class-component'
import WebpushServiceWorker from '../subscription/WebpushServiceWorker.vue'

@Options({
  components: {},
})
export default class GuestModeMenu extends mixins(WebpushServiceWorker) {
  async onTurnOnNotification() {
    await this.registerServiceWorker(this.currentUrl)
    this.$q.notify({
      type: 'positive',
      message: 'Done',
    })
  }

  get currentUrl() {
    const path = this.$router.resolve({
      name: 'guest_page',
      params: { guestToken: this.$guestToken() },
    })?.fullPath

    return path
  }

  get storeKey() {
    return `service-worker-${this.$guestToken()}`
  }

  get isEnableNotification() {
    return localStore.getItem(this.storeKey)
  }

  mounted() {
    if (!this.isEnableNotification) {
      this.onTurnOnNotification()
      localStore.setItem(this.storeKey, 'already')
    }
  }
}
</script>
