import { render } from "./TaskListTableGuestMode.vue?vue&type=template&id=89496da0&scoped=true"
import script from "./TaskListTableGuestMode.vue?vue&type=script&lang=ts"
export * from "./TaskListTableGuestMode.vue?vue&type=script&lang=ts"

import "./TaskListTableGuestMode.vue?vue&type=style&index=0&id=89496da0&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-89496da0"

export default script
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpace,QBtnToggle,QBtn,QMenu,QList,QItem,QItemSection});qInstall(script, 'directives', {ClosePopup});
