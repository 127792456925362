
import { localStore } from '@/utils/localstore'
import { Options, mixins } from 'vue-class-component'
import WebpushServiceWorker from '../subscription/WebpushServiceWorker.vue'

@Options({
  components: {},
})
export default class GuestModeMenu extends mixins(WebpushServiceWorker) {
  async onTurnOnNotification() {
    await this.registerServiceWorker(this.currentUrl)
    this.$q.notify({
      type: 'positive',
      message: 'Done',
    })
  }

  get currentUrl() {
    const path = this.$router.resolve({
      name: 'guest_page',
      params: { guestToken: this.$guestToken() },
    })?.fullPath

    return path
  }

  get storeKey() {
    return `service-worker-${this.$guestToken()}`
  }

  get isEnableNotification() {
    return localStore.getItem(this.storeKey)
  }

  mounted() {
    if (!this.isEnableNotification) {
      this.onTurnOnNotification()
      localStore.setItem(this.storeKey, 'already')
    }
  }
}
