
import BaseModel from '../../models/BaseModel'

export interface ISubscription {
  endpoint: string
}

export interface IUserSubscription {
  _id?: string
  subscriptions?: ISubscription[]
}

export class SubscriptionModel extends BaseModel {
  _id?: string
  subscriptions?: ISubscription[]
  updatedAt?: string

  sertialize(): IUserSubscription {
    return {
      _id: this._id,
      subscriptions: this.subscriptions,
    }
  }
}
