<template>
  <q-header elevated>
    <q-toolbar class="bg-white text-black guest-header-toolbar">
      <q-toolbar-title>
        <q-btn flat dense padding="0" icon="view_quilt" :style="{ color: project.color }" />
        <span class="text-black">{{ project.title }}</span>
      </q-toolbar-title>
      <div class="row items-center q-gutter-sm">
        <q-btn flat no-caps>
          <UserAvatar :size="30" :user="guestInfo" />
          <span class="q-ml-sm">{{ guestInfo.displayName }}</span>
        </q-btn>
        <span class="text-primary">
          <ActivityButton />
        </span>
      </div>
    </q-toolbar>
  </q-header>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { IProject } from '../project/project-model'
import { UserGuestModel } from '../user/user-model'
import ActivityButton from '../activity/ui/ActivityButton.vue'
import UserAvatar from '../user/ui/UserAvatar.vue'

@Options({
  components: { ActivityButton, UserAvatar },
})
export default class GuestModeHeader extends Vue {
  get project(): IProject {
    return this.$store.getters.project
  }

  get guestInfo(): UserGuestModel {
    return this.$store.getters.guestInfo || {}
  }
}
</script>
