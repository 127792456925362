import gql from 'graphql-tag'

const baseReturn = `
  _id
  subscriptions
`

const SUBSCRIPTION_GQL = {
  ADD_MUTATION: gql`
    mutation subscription_add($input: SubscriptionInput!) {
      subscription_add(input: $input) {
        ${baseReturn}
      }
    }
  `,
}

export default SUBSCRIPTION_GQL
