
import { Vue, Options } from 'vue-class-component'
import { IProject } from '../project/project-model'
import { UserGuestModel } from '../user/user-model'
import ActivityButton from '../activity/ui/ActivityButton.vue'
import UserAvatar from '../user/ui/UserAvatar.vue'

@Options({
  components: { ActivityButton, UserAvatar },
})
export default class GuestModeHeader extends Vue {
  get project(): IProject {
    return this.$store.getters.project
  }

  get guestInfo(): UserGuestModel {
    return this.$store.getters.guestInfo || {}
  }
}
