<template>
  <section>
    <div class="row items-center my-list-heading q-mt-lg">
      <div class="text-h6">My list</div>
      <q-space />
      <q-btn-toggle
        v-model="filteredTasks.filterStateParent"
        no-caps
        outline
        padding="0 10px"
        toggle-color="is-active"
        :options="[
          { label: 'All', value: '' },
          { label: 'New', value: 'new' },
          { label: 'Doing', value: 'doing' },
          { label: 'Done', value: 'done' },
        ]"
      />
    </div>
    <div class="task-list-container task-list-grid">
      <div class="task-list-grid__row items-center" v-if="tasksFiltered.length">
        <!-- Headings -->
        <div
          v-for="column in visibleColumns"
          :key="column.name"
          :class="
            `row whitespace-nowrap items-center c-animate task-list-grid--heading task-list-grid__row-item task-list-grid__row-item--${column.name}`
          "
        >
          <div>
            {{ column.label }}
          </div>
        </div>
      </div>

      <!-- Body -->
      <template v-for="task in tasksFiltered" :key="task._id">
        <div class="task-list-grid__row items-center">
          <div
            v-for="column in visibleColumns"
            :key="column.name"
            :class="{
              [`task-list-grid--body task-list-grid__row-item task-list-grid__row-item--${column.name}`]: true,
              'is-new': !task.isSeen,
            }"
          >
            <!-- Task title -->
            <div
              v-if="column.name === 'title'"
              @click="onEditTask(task)"
              class="cursor-pointer c-hover-trans-up w-full"
            >
              <div class="row items-center no-wrap q-gutter-sm">
                <span class="ellipsis flex-grow">
                  {{ task.title }}
                </span>
                <TaskCommentCountBadge :task="task" />
              </div>
            </div>

            <!-- Creator -->
            <div v-if="column.name === 'creator'">
              <UserAvatar :user="task.creator" :size="25" />
            </div>

            <!-- custom fields -->
            <div v-if="isCustomField(column.name)">
              <component
                :is="customFieldComponentsViewList[column.type]"
                :value="task.customFieldsVal[column.name]"
                v-bind="{ customField: column, showLabel: false }"
                @update:change="payload => onTaskFieldChange(task, payload)"
              />
            </div>

            <!-- updatedAt -->
            <div v-if="column.name === 'updatedAt'">
              <span v-if="task.updatedAt" class="text-grey">
                {{ formatDate(task.updatedAt) }}
              </span>
            </div>

            <!-- parent state -->
            <div v-if="column.name === 'status'">
              <TaskParentStatus :task="task" />
            </div>

            <!-- action -->
            <div v-if="column.name === 'action'">
              <q-btn icon="more_horiz" padding="xs" flat class="text-grey-5">
                <q-menu class="max-w-reset">
                  <q-list dense>
                    <q-item clickable v-close-popup @click="onDeleteTask(task)">
                      <q-item-section> Delete task </q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </div>
          </div>
        </div>
      </template>

      <Empty v-if="!tasksFiltered?.length" />
    </div>
  </section>

  <TaskFormModalGuest
    v-if="modalTaskFormVisible"
    :taskData="selected"
    :modalVisible="modalTaskFormVisible"
    @update:closeModal="onCloseModal"
  />
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ICustomField, ICustomFieldChange } from '@/components/custom-field/custom-field-model'
import { ETaskState, TaskModel } from '@/components/task/task-model'
import { isCustomField } from '@/utils/helpers'
import { CUSTOM_FIELD_DISPLAY_IN_TABLE } from '@/constants/vars'

import logging from '@/utils/logging'
import CustomFieldMixin from '@/components/custom-field/mixins/CustomFieldMixin.vue'
import UserAvatar from '@/components/user/ui/UserAvatar.vue'
import Empty from '@/components/common/ui/Empty.vue'
import TaskDisplayMixinGuestMode from './mixins/TaskDisplayMixinGuest.vue'
import TaskFormModalGuest from './TaskFormModalGuest.vue'
import TaskParentStatus from './ui/TaskParentStatus.vue'
import TaskCommentCountBadge from '../ui/TaskCommentCountBadge.vue'

@Options({
  components: {
    Empty,
    TaskCommentCountBadge,
    TaskParentStatus,
    TaskFormModalGuest,
    UserAvatar,
  },
  directives: { maska },
})
export default class TaskListTableGuestMode extends mixins(TaskDisplayMixinGuestMode, CustomFieldMixin) {
  isCustomField = isCustomField

  get columns() {
    return [
      {
        label: 'Title',
        name: 'title',
      },
      // Display custom fields column
      ...this.customFieldsToDisplay,
      {
        label: 'Status',
        name: 'status',
      },
      {
        label: 'Last updated',
        name: 'updatedAt',
      },
      {
        label: '#',
        name: 'action',
      },
    ]
  }

  get customFields(): ICustomField[] {
    return this.project?.form?.fields || []
  }

  get customFieldsToDisplay() {
    return this.customFields.filter(field => {
      return field.type && CUSTOM_FIELD_DISPLAY_IN_TABLE.includes(field.type)
    })
  }

  get visibleColumns() {
    return this.columns
  }

  taskStateChange(task: TaskModel, newState: ETaskState) {
    this.updateTask({ _id: task._id, state: newState })
  }

  // Emit from each of custom field form
  // Or static task fields
  onTaskFieldChange(task: TaskModel, payload: ICustomFieldChange) {
    // [TaskMixin]
    this.doUpdateTaskField(task.sertialize(), payload)
  }

  created() {
    logging.debugRender(TaskListTableGuestMode.name)
  }
}
</script>
<style lang="scss" scoped>
.my-list-heading {
  padding: 5px 0;
  border-bottom: 2px solid #999;
}
.task-list-grid {
  min-height: 150px;
}
</style>
