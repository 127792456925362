
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { TaskModel } from '@/components/task/task-model'

import logging from '@/utils/logging'
import TaskFormGuest from './ui/TaskFormGuest.vue'

@Options({
  components: {
    TaskFormGuest,
  },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class TaskFormModalGuest extends Vue {
  @Prop({ default: {} })
  taskData!: TaskModel

  @Prop()
  modalVisible!: boolean

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  onCancel() {
    this.visible = true
  }

  beforeMount() {
    logging.debugRender(TaskFormModalGuest.name)
  }
}
