
import { Options, mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { formatDate } from '@/utils/helpers'
import { TaskModel } from '@/components/task/task-model'
import { IWorkflowState } from '@/components/workflow/workflow-model'
import TaskMixin from '../../mixins/TaskMixin.vue'
import TaskDisplayFilterMixin from '../../mixins/TaskDisplayFilterMixin.vue'

@Options({
  components: {},
})
export default class TaskDisplayMixinGuestMode extends mixins(TaskMixin, TaskDisplayFilterMixin) {
  modalTaskFormVisible = false

  selected: TaskModel | null = null
  formatDate = formatDate

  get taskId() {
    return this.$route.params.taskId as string
  }

  set taskId(taskId: string) {
    this.$router.push({
      name: 'guest_page.tasks',
      params: { taskId },
    })
  }

  get project() {
    return this.$store.getters.project
  }

  get tasks(): TaskModel[] {
    return this.$store.getters.tasksOfGuest
  }

  get workflowStates(): IWorkflowState[] {
    return this.project?.workflow?.states || []
  }

  get stateParentMapping() {
    const mapping: Record<string, string> = {}
    for (const state of this.workflowStates) {
      mapping[state.value] = state.type
    }

    return mapping
  }

  get tasksFiltered(): TaskModel[] {
    return this.tasks.filter((task: TaskModel) => {
      // Match by search keyword
      const toSearch = task.title?.toLowerCase() || ''
      const keyword = this.filteredTasks.searchQuery.toLowerCase()
      const match1 = !this.filteredTasks.searchQuery || toSearch.indexOf(keyword) >= 0

      // Match by creator
      const creatorIds = this.filteredTasks?.creatorIds || []
      const match2 = !creatorIds.length || !task.creatorId || creatorIds.includes(task.creatorId)

      const match3 =
        !this.filteredTasks.filterStateParent ||
        !task.state ||
        this.stateParentMapping[task.state] === this.filteredTasks.filterStateParent

      // Selected projects only
      return match1 && match2 && match3
    })
  }

  @Watch('taskId', { immediate: true })
  taskIdChanged() {
    this.modalTaskFormVisible = !!this.taskId
    this.selected = new TaskModel({ _id: this.taskId })
  }

  onDeleteTask(task: TaskModel) {
    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Are you sure you want to delete?',
        cancel: true,
        persistent: true,
      })
      .onOk(() => {
        this.removeTask(task)
      })
  }

  onCloseModal() {
    this.selected = null
    this.taskId = ''
    this.modalTaskFormVisible = false
  }

  onEditTask(task: TaskModel) {
    if (!task._id) {
      return
    }

    this.selected = task
    this.taskId = task._id
  }
}
