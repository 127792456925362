<template>
  <CLoading v-if="loading" />
  <template v-if="!loading">
    <GuestModeHeader />
    <q-page-container class="q-pb-xl">
      <q-card class="guest-mode-container q-mx-auto">
        <q-card-section>
          <div class="row items-center no-wrap q-mb-md">
            <h1 class="text-h6 flex-grow">Submit new item</h1>
            <GuestModeMenu />
          </div>
          <q-card flat class="task-form-in-line">
            <TaskFormGuest />
          </q-card>
          <TaskListTableGuestMode />
        </q-card-section>
      </q-card>
    </q-page-container>
  </template>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { Watch } from 'vue-property-decorator'
import { UserGuestModel } from '@/components/user/user-model'

import TaskMixin from '@/components/task/mixins/TaskMixin.vue'
import logging from '@/utils/logging'
import CLoading from '@/components/common/ui/CLoading.vue'
import ProfileMixin from '@/components/account/mixins/ProfileMixin.vue'
import ProjectMixin from '@/components/project/mixins/ProjectMixin.vue'
import GuestModeHeader from '@/components/guest-mode/GuestModeHeader.vue'
import TaskFormGuest from '@/components/task/guest-mode/ui/TaskFormGuest.vue'
import TaskListTableGuestMode from '@/components/task/guest-mode/TaskListTableGuestMode.vue'
import GuestModeMenu from '@/components/guest-mode/GuestModeMenu.vue'

@Options({
  components: { TaskListTableGuestMode, CLoading, GuestModeHeader, TaskFormGuest, GuestModeMenu },
  directives: { maska },
})
export default class GuestPage extends mixins(ProfileMixin, ProjectMixin, TaskMixin) {
  isComponentReady = false

  get loading() {
    return !this.isComponentReady || !this.projectId || this.$store.state.task.loading
  }

  get guestToken() {
    return this.$guestToken()
  }

  get guestInfo(): UserGuestModel {
    return this.$store.getters.guestInfo || {}
  }

  get projectId() {
    return this.guestInfo?.projectId
  }

  @Watch('guestInfo')
  guestInfoChange() {
    if (this.guestInfo.displayName) {
      this.$meta.setMeta({ title: [this.guestInfo.displayName] })
    }
  }

  @Watch('guestToken', { immediate: true })
  async guestTokenChanged() {
    if (!this.guestToken) {
      return
    }

    const isLoginSuccess = await this.secondLoginWithGuest()
    if (isLoginSuccess) {
      await this.checkValidGuest()

      if (!this.projectId) {
        this.isComponentReady = true
        return
      }

      await this.getProject(this.projectId)
      await this.getGuestTasks()
      this.isComponentReady = true
    }
  }

  beforeMount() {
    logging.debugRender(GuestPage.name)
  }
}
</script>
<style lang="scss" scoped>
.guest-mode-container {
  max-width: 980px;
}
.task-form-in-line {
  .task-form-card {
    max-width: 100% !important;
  }
}
</style>
