
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ICustomField, ICustomFieldChange } from '@/components/custom-field/custom-field-model'
import { ETaskState, TaskModel } from '@/components/task/task-model'
import { isCustomField } from '@/utils/helpers'
import { CUSTOM_FIELD_DISPLAY_IN_TABLE } from '@/constants/vars'

import logging from '@/utils/logging'
import CustomFieldMixin from '@/components/custom-field/mixins/CustomFieldMixin.vue'
import UserAvatar from '@/components/user/ui/UserAvatar.vue'
import Empty from '@/components/common/ui/Empty.vue'
import TaskDisplayMixinGuestMode from './mixins/TaskDisplayMixinGuest.vue'
import TaskFormModalGuest from './TaskFormModalGuest.vue'
import TaskParentStatus from './ui/TaskParentStatus.vue'
import TaskCommentCountBadge from '../ui/TaskCommentCountBadge.vue'

@Options({
  components: {
    Empty,
    TaskCommentCountBadge,
    TaskParentStatus,
    TaskFormModalGuest,
    UserAvatar,
  },
  directives: { maska },
})
export default class TaskListTableGuestMode extends mixins(TaskDisplayMixinGuestMode, CustomFieldMixin) {
  isCustomField = isCustomField

  get columns() {
    return [
      {
        label: 'Title',
        name: 'title',
      },
      // Display custom fields column
      ...this.customFieldsToDisplay,
      {
        label: 'Status',
        name: 'status',
      },
      {
        label: 'Last updated',
        name: 'updatedAt',
      },
      {
        label: '#',
        name: 'action',
      },
    ]
  }

  get customFields(): ICustomField[] {
    return this.project?.form?.fields || []
  }

  get customFieldsToDisplay() {
    return this.customFields.filter(field => {
      return field.type && CUSTOM_FIELD_DISPLAY_IN_TABLE.includes(field.type)
    })
  }

  get visibleColumns() {
    return this.columns
  }

  taskStateChange(task: TaskModel, newState: ETaskState) {
    this.updateTask({ _id: task._id, state: newState })
  }

  // Emit from each of custom field form
  // Or static task fields
  onTaskFieldChange(task: TaskModel, payload: ICustomFieldChange) {
    // [TaskMixin]
    this.doUpdateTaskField(task.sertialize(), payload)
  }

  created() {
    logging.debugRender(TaskListTableGuestMode.name)
  }
}
