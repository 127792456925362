
import { Vue, Options } from 'vue-class-component'
import { urlBase64ToUint8Array } from '../../utils/helpers'
import logging from '../../utils/logging'
import SubscriptionApi from './subscription-api'

@Options({
  components: {},
})
export default class WebpushServiceWorker extends Vue {
  get publicVapidKey() {
    return process.env.VUE_APP_PUBLIC_VAPID_KEY
  }

  async registerServiceWorker(scope?: string) {
    if (!this.publicVapidKey) {
      return false
    }

    if ('serviceWorker' in navigator) {
      // register service worker
      const register = await navigator.serviceWorker.register('/service-worker.js', {
        scope: scope || '/',
      })

      await navigator.serviceWorker.ready

      // register push
      logging.debug('registering service worker')
      const subscription = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(this.publicVapidKey),
      })

      await SubscriptionApi.add(subscription)
    }
  }
}
