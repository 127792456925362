import { render } from "./GuestModeHeader.vue?vue&type=template&id=16653a50"
import script from "./GuestModeHeader.vue?vue&type=script&lang=ts"
export * from "./GuestModeHeader.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QHeader,QToolbar,QToolbarTitle,QBtn});
