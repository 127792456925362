<template>
  <q-card flat class="task-form-card" :class="project?.isAllowGuestPost ? 'bg-grey-2' : 'bg-red-5'" v-if="project?._id">
    <CLoading v-if="loading" />
    <!-- Button close if form in a modal -->
    <template v-if="inModal">
      <q-btn flat round dense icon="close" v-close-popup class="btn-close-modal" />
    </template>
    <div v-if="!project.isAllowGuestPost" class="text-white">
      You can not create ticket now due by ticket policy.
    </div>
    <q-form ref="formRef" class="row column" greedy v-if="!loading && !isNotFound && project.isAllowGuestPost">
      <q-card-section>
        <!-- Task state -->
        <TaskParentStatus v-if="form._id" :task="form" />
        <!-- Title task -->
        <div class="flex-grow">
          <CustomFieldInput
            :key="componentKey"
            :customField="{
              type: 'input',
              name: 'title',
              label: 'Task title',
              rules: [{ ruleName: 'isRequired' }],
              class: 'text-h5 text-bold',
            }"
            :isNew="isNew"
            :showLabel="isNew"
            v-model="form.title"
            @update:change="onTaskFieldChange"
          />
        </div>
        <!-- Workspace custom fields -->
        <div v-for="(customField, index) in customFields" :key="index">
          <q-space class="q-my-md" />
          <component
            :key="componentKey"
            :is="customFieldComponents[customField.type]"
            v-bind="{ customField, project, isNew }"
            v-model="form.customFieldsVal[customField.name]"
            @update:change="onTaskFieldChange"
          />
        </div>

        <!-- Save button -->
        <div class="q-mt-xl text-center q-gutter-sm" v-if="isNew">
          <q-btn push color="primary" padding="8px 40px" @click="onSubmit"> Submit </q-btn>
        </div>
      </q-card-section>

      <TaskComments :task="taskData" />

      <q-card-section class="text-right" v-if="!isNew">
        <q-space class="q-my-md" />
        <div v-if="form.updatedAt" class="text-grey-8">
          <div class="text-grey-6">Last updated</div>
          <div>
            {{ formatDateTime(form.updatedAt) }}
          </div>
        </div>
      </q-card-section>
    </q-form>
    <NotFound :showBackButton="false" v-if="isNotFound" />
  </q-card>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { QForm } from 'quasar'
import { formatDateTime } from '@/utils/helpers'
import { TaskModel, ITask, ETaskState } from '@/components/task/task-model'
import { ICustomFieldChange } from '@/components/custom-field/custom-field-model'

import cloneDeep from 'lodash/cloneDeep'
import TaskMixin from '@/components/task/mixins/TaskMixin.vue'

import UserAvatar from '@/components/user/ui/UserAvatar.vue'
import TaskApi from '@/components/task/task-api'
import logging from '@/utils/logging'
import CLoading from '@/components/common/ui/CLoading.vue'
import CustomFieldMixin from '@/components/custom-field/mixins/CustomFieldMixin.vue'
import NotFound from '@/components/common/NotFound.vue'
import CustomFieldInput from '@/components/custom-field/fields/input/CustomFieldInput.vue'
import TaskParentStatus from './TaskParentStatus.vue'
import TaskComments from '@/components/comment/ui/TaskComments.vue'

const initForm: ITask = {
  title: '',
  description: '',
  customFieldsVal: {},
  state: ETaskState.undone,
}

@Options({
  components: {
    CLoading,
    NotFound,
    UserAvatar,
    TaskComments,
    CustomFieldInput,
    TaskParentStatus,
  },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class TaskFormGuest extends mixins(TaskMixin, CustomFieldMixin) {
  @Prop({ default: {} })
  taskData!: TaskModel

  @Prop({ default: false })
  inModal!: boolean

  componentReady = false
  componentKey = 1
  isNotFound = false

  formatDateTime = formatDateTime

  form = cloneDeep(initForm)

  get loading() {
    return !this.componentReady
  }

  get taskId() {
    return this.taskData?._id
  }

  get project() {
    return this.$store.getters.project
  }

  get customFields() {
    return this.project?.form?.fields || []
  }

  get isNew() {
    return !this.taskId
  }

  @Watch('taskId', { immediate: true })
  async taskIdChanged() {
    if (this.taskId) {
      const task = await TaskApi.single(this.taskId)
      this.componentReady = true
      if (!task?._id) {
        this.isNotFound = true
        return
      }

      this.form = { ...this.form, ...cloneDeep(task) }
      this.updateTaskSeen(task)
    }

    this.form = { ...this.form, ...cloneDeep(this.taskData) }
    this.componentReady = true
  }

  onSubmit() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSaveTask()
        this.resetForm()
        this.onCancel()
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  async doSaveTask() {
    if (this.isNew) {
      const { ...addData } = this.form
      return this.addTask(this.project._id, addData)
    } else {
      // eslint-disable-next-line
      const { state, ...saveData } = this.form
      return this.updateTask(saveData)
    }
  }

  onStateToggleChange(newState: ETaskState) {
    this.form.state = newState

    // Update immediaty if edit mode
    if (!this.isNew) {
      this.updateTask({ _id: this.taskId, state: newState })
    }
  }

  @Emit('update:closeModal')
  onCancel() {
    return true
  }

  resetForm() {
    this.form = cloneDeep(initForm)
    this.componentKey++
  }

  // Apart of form changed event
  // Using for save task edit mode info on each field
  onTaskFieldChange(payload: ICustomFieldChange) {
    // Don't save a part of task on createing node
    if (this.isNew || !this.componentReady || !this.$refs.formRef) {
      return
    }

    const form = this.$refs.formRef as QForm
    form.validate().then(async (success: boolean) => {
      if (!success) {
        return false
      }

      // [TaskMixin]
      this.doUpdateTaskField(this.form, payload)
    })
    return true
  }

  beforeMount() {
    logging.debugRender(TaskFormGuest.name)
  }
}
</script>
<style lang="scss" scoped>
.task-form-card {
  border-radius: 8px;
}
</style>
