import logging from '@/utils/logging'
import SUBSCRIPTION_GQL from './subscription-graphql'
import { ISubscription, SubscriptionModel } from '@/components/subscription/subscription-model'
import { NormApi } from '../../api/norm'

export class SubscriptionApi extends NormApi<SubscriptionModel> {
  constructor() {
    super()
    this.setClassRef(SubscriptionModel)
  }

  add = async (payload: ISubscription): Promise<SubscriptionModel | null> => {
    return this.mutation({
      query: SUBSCRIPTION_GQL.ADD_MUTATION,
      variables: {
        input: payload,
      },
      success: () => {
        logging.debug('Subscription saved')
      },
    })
  }
}

export default new SubscriptionApi()
